<template>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-2xl">
      <h2 class="text-xl font-bold text-gray-900 mb-4"></h2>
      
        <div class="space-y-8">
          <div class="p-2 bg-green-100 shadow-md rounded-md border-2 border-green-100 relative mt-6">
              <p class="text-green-700 mb-1">
                Please check your email for the password reset link. Be sure to also check your Spam or Junk email folders.
                  </p>
            </div>
        </div>
      
        <div class="mt-8 flex justify-between">
            <button class="bg-gray-200 text-gray-800 rounded-lg px-5 py-2 hover:bg-gray-300" @click="emitClose()">Ok</button>
        </div>
    </div>
  </template>

  <script setup>
  import { defineEmits} from 'vue';

  const emit = defineEmits(['close']);
  const emitClose = () => {
    emit('close');
  };
  </script>

  <style>
  /* Additional custom styling if needed */
  </style>
