import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import About from "../views/AboutView.vue";
import SignIn from "../views/SingInFlow/SignIn";
import Request from "../views/SingInFlow/Request";
import Recover from "../views/SingInFlow/Recover";
import FuneralAgreement from "../views/SingInFlow/FuneralAgreement";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/request",
    name: "request",
    component: Request,
  },
  {
    path: "/recover",
    name: "recover",
    component: Recover,
  },
  {
    path: "/funeral-agreement",
    name: "FuneralAgreement",
    component: FuneralAgreement,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem("token"); // Check if token exists in localStorage

  if (requiresAuth && !isAuthenticated) {
    next({ name: "signin" }); // Redirect to signin if not authenticated
  } else {
    next(); // Proceed to route
  }
});

export default router;
