<template>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-5xl">
      <h2 class="text-xl font-bold text-gray-900 mb-4">Step 2: Composition Information</h2>
        <div class="space-y-4">
          <div class="p-4 bg-white shadow-md rounded-md border-2 border-red-100 relative">
            <div class="absolute top-0 left-0 right-0 bg-red-100 px-3 py-1 mt-6t">
              <h4 class="text-red-700 font-bold text-sm">Composition Information</h4>
            </div>
            
            <p class="text-gray-700 text-lg text-left mt-6">
             Song(s) Covered in your Video
            </p>
            <div class="p-4 bg-white shadow-md rounded-md border-2 border-blue-900 relative mt-6">
              <div class="absolute top-0 left-0 right-0 bg-blue-900 px-3 py-1 flex items-center">
                <div class="flex items-center space-x-2">
                  <h4 class="text-white font-bold text-sm">Composition Information</h4>
                  <div class="flex items-center justify-center rounded-full bg-white px-1.5 text-xs font-medium leading-none text-blue-900">
                    0
                  </div>
                </div>
              </div>
              <p class="text-gray-700 text-xs text-left mt-6">
              Compositions
              </p>
              <div class="overflow-x-auto relative mt-6">
                <table class="w-full text-sm text-left text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase">
                    <tr class="border-b">
                      <th scope="col" class="py-1 px-6">
                        Song Title
                      </th>
                      <th scope="col" class="py-1 px-6">
                        Composer(s)
                      </th>
                      <th scope="col" class="py-1 px-6">
                        Artist(s)
                      </th>
                      <th scope="col" class="py-1 px-6">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(song, index) in paginatedLibrary" :key="song.id" class="border-b">
                      <td class="py-1 px-6">
                        {{ song.title }}
                      </td>
                      <td class="py-1 px-6">
                        {{ song.composer_names }}
                      </td>
                      <td class="py-1 px-6">
                        {{ song.artist_names }}
                      </td>
                      <td class="py-1 px-6 text-right">
                        <button @click="deleteSong(index)" class="text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-800 focus:ring-opacity-50 rounded-lg px-2 py-1 text-xs">
                          Remove
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex mt-4 justify-end" v-if="totalPages > 1">
                  <span @click="changePage(currentPage - 1)" class="cursor-pointer px-2"> &lt; </span>
                  <!-- Current Page -->
                  <span @click="changePage(currentPage)" class="cursor-pointer px-2 text-blue-500 font-bold">
                    {{ currentPage }}
                  </span>
                  
                  <!-- Next Page (if applicable) -->
                  <span v-if="currentPage < totalPages" @click="changePage(currentPage + 1)" class="cursor-pointer px-2">
                    {{ currentPage + 1 }}
                  </span>
                  <span @click="changePage(currentPage + 1)" class="cursor-pointer px-2"> &gt; </span>
                </div>
              </div>
                   
            </div>
            <div>
              <p class="text-gray-500 text-xs text-left mt-6">
                Search our song library below by title and/or composer / songwriter. Songs wil populate automatically as you type.
              </p>
              <div class="flex justify-between mb-4">
                <div class="flex flex-col">
                  <div class="flex mt-2">
                    <input v-model="searchTitleQuery" class="shadow appearance-none border rounded-l w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Song Title">
                    <div class="bg-gray-200 flex items-center justify-center w-12 rounded-r">
                      <template v-if="isLoading">
                        <!-- Loader Icon or Animation -->
                        <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                      </template>
                      <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-gray-600">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex mt-2">
                    <input v-model="searchArtistQuery" class="shadow appearance-none border rounded-l w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Artist(s)">
                    <div class="bg-gray-200 flex items-center justify-center w-12 rounded-r">
                      <template v-if="isLoading">
                        <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                      </template>
                      <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-gray-600">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex mt-2">
                    <input v-model="searchComposerQuery" class="shadow appearance-none border rounded-l w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Composer(s)/Songwriter(s)">
                    <div class="bg-gray-200 flex items-center justify-center w-12 rounded-r">
                      <template v-if="isLoading">
                        <!-- Loader Icon or Animation -->
                        <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                      </template>
                      <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-gray-600">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <ul v-if="library.length!=0" class="absolute z-10 w-full w-100 bg-white mt-1 rounded-md shadow-lg">
                     <!-- Heading -->
                     <li class="flex justify-between p-3 border-b text-gray-900 bg-gray-100 uppercase font-bold">
                      <span class="flex-1">Song Title</span>
                      <span class="flex-1 text-center">Artist</span>
                      <span class="flex-1 text-right">Composer</span>
                    </li>

                    <!-- Song List -->
                    <li v-for="song in library" :key="song.id" @click="addSong(song)" class="cursor-pointer p-3 hover:bg-gray-100 border-b flex justify-between">
                      <span class="flex-1">{{ song.title }}</span>
                      <span class="flex-1 text-center">{{ song.artist_names }}</span>
                      <span class="flex-1 text-right">{{ song.composer_names }}</span>
                    </li>
                  </ul>
            </div>
            <div class="p-4 bg-red-100 shadow-md rounded-md border-2 border-red-100 relative mt-6" v-if="isEmptyCompositions">
              <p class="text-red-700 mb-1 text-xs text-left">
                * No Results Found
              </p>
            </div>
            <div class="p-4 bg-green-100 shadow-md rounded-md border-2 border-green-100 relative mt-6" v-if="isEmptyCompositions">
              <p class="text-green-700 mb-1 text-xs text-center">
                You may use the button below to request this song be added to the database.
              </p>
              <button class="bg-blue-900 text-white rounded-lg px-5 py-2 hover:bg-blue-900" @click="showCompositionModal = true">Add New Composition</button>
            </div>
            <div class="p-4 bg-red-100 shadow-md rounded-md border-2 border-red-100 relative mt-6" v-if="form.compositions.length == 0">
              <p class="text-red-700 mb-1 text-xs text-left">
                * Please add a composition to proceed
              </p>
            </div>
          </div>
        </div>
      
        <div class="mt-8 flex justify-between">
            <button class="bg-gray-200 text-gray-800 rounded-lg px-5 py-2 hover:bg-gray-300" @click="emitClose()">Cancel</button>
            <div class="flex items-center space-x-3">
                <button class="bg-red-800 text-white rounded-lg px-5 py-2 hover:bg-red-900" @click="emitPrevious()">Back</button>
                <button @click="validateAndNext()" class="bg-red-500 text-white rounded-lg px-5 py-2 hover:bg-red-600">Next</button>
            </div>
        </div>
              <!-- Modal -->
        <div v-if="showCompositionModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <AddComposition :titleQuery="searchTitleQuery" :artistQuery="searchArtistQuery" :composerQuery="searchComposerQuery" @save="addNewComposition" @close="handleClose" />
        </div>
    </div>
  </template>

  <script setup>
  import { defineEmits, onMounted, ref, reactive, computed, watch } from 'vue';
  import axios from 'axios';
  import AddComposition from './AddComposition.vue';

  const emit = defineEmits(['next', 'close', 'previous']);
  const isEmptyCompositions = ref(false)
  const showCompositionModal= ref(false)
  const form = ref({
    compositions: []
  });
  const isLoading = ref(false);
  const searchTitleQuery = ref ('');
  const searchComposerQuery = ref ('');
  const searchArtistQuery = ref ('');
  let searchTimeout = null;
  const library = ref([]);

const rowsPerPage = 3;
const currentPage = ref(1);

const paginatedLibrary = computed(() => {
  const start = (currentPage.value - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  return form.value.compositions.slice(start, end);
});

const changePage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const totalPages = computed(() => {
  return Math.ceil(form.value.compositions.length / rowsPerPage);
});


const fetchCompositions = async (params) => {
  isLoading.value = true;
  try {
    const response = await axios.post(axios.defaults.baseURL + "video-upload/searchfilter-compositions/", { 
      title: params.title? params.title: null,
      artist: params.artist? params.artist: null,
      composer: params.composer? params.composer: null,
    });
    if (response.data.results.length > 0){
      isEmptyCompositions.value =  false
      library.value = response.data.results;
    } else {
      isEmptyCompositions.value =  true
      library.value = response.data.results;
    }
  } catch (error) {
    console.error('Error fetching compositions:', error);
    library.value = []; // Reset on error
  } finally {
    isLoading.value = false; // Stop loading
  }
};

const initiateSearch = () => {
  const params = {};
  if (searchTitleQuery.value.trim()) params.title = searchTitleQuery.value.trim();
  if (searchArtistQuery.value.trim()) params.artist = searchArtistQuery.value.trim();
  if (searchComposerQuery.value.trim()) params.composer = searchComposerQuery.value.trim();

  // Only make the API call if at least one parameter is not empty
  if (Object.keys(params).length > 0) {
    fetchCompositions(params);
  }
};

// Watcher for all three queries
watch([searchTitleQuery, searchArtistQuery, searchComposerQuery], () => {
  // Clear the existing timeout to ensure only the latest call is made after a delay
  if (searchTimeout) clearTimeout(searchTimeout);
  
  searchTimeout = setTimeout(initiateSearch, 1000); // 1-second delay
}, { deep: true });

const filteredTitle = computed(() => {
    return searchTitleQuery.value ? library.value.filter(e => e.title.toLowerCase().includes(searchTitleQuery.value.toLowerCase())) : [];
  });
  const filteredComposer = computed(() => {
    return searchComposerQuery.value ? library.value.filter(e => e.composer.toLowerCase().includes(searchComposerQuery.value.toLowerCase())) : [];
  });
  const filteredArtist = computed(() => {
    return searchArtistQuery.value ? library.value.filter(e => e.artist.toLowerCase().includes(searchArtistQuery.value.toLowerCase())) : [];
  });

  const addSong = (song) => {
    const isSongExists = form.value.compositions.some((composition) => composition.id === song.id);
    // If the song's id does not exist, push the song into the compositions array
    if (!isSongExists) {
      form.value.compositions.push(song);
    }
    // Clear the search queries
    searchTitleQuery.value = '';
    searchComposerQuery.value = '';
    searchArtistQuery.value = '';
    library.value = [];
  };
  const deleteSong = (index) => {
  form.value.compositions.splice(index, 1);
};
//   const requirementTypes = ref([]);
//   const showError = ref(false);

//   onMounted(async () => {
//     try {
//       const response = await axios.get('/requirement-types');
//       requirementTypes.value = response.data;
//     } catch (error) {
//       console.error('Error fetching requirement types:', error);
//     }
//   });

  const validateAndNext = () => {
    if(form.value.compositions.length == 0){
      return
    }
    emit('next', {
            data: form.value
        });
  };

  const emitClose = () => {
    emit('close');
  };
  
  const emitPrevious = () => {
    emit('previous');
  };

  const handleClose = () => {
    showCompositionModal.value = false;
  };
  function addNewComposition (data) {
    form.value.compositions.push(data);
    console.log(data)
    // Clear the search queries
    searchTitleQuery.value = '';
    searchComposerQuery.value = '';
    searchArtistQuery.value = '';
    library.value = [];
    showCompositionModal.value = false;
    isEmptyCompositions.value = false;
  };
</script>

  <style>
  /* Additional custom styling if needed */
  </style>
