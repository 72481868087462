<template>
  <!-- <div class="container">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" v-model="firstName" required>
      </div>

      <div class="form-group">
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" v-model="lastName" required>
      </div>

      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>
      </div>

      <div class="form-group">
        <label for="note">How did you hear about us?</label>
        <textarea id="note" v-model="note" rows="4" required></textarea>
      </div>

      <vue-recaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired">
      </vue-recaptcha> 
       :disabled="!captchaVerified"
      <button type="submit" >Submit</button>
    </form>
  </div> -->

  <div class="container">
    <div class="request">
      Go to Signin?
      <router-link to="/signin">Login</router-link>
    </div>
    <div class="login">
      <h1>
        <span class="faded_h1">li</span>Sync<span class="faded_h1">e</span><span class="green_h1">.</span>
      </h1>
      <h4>Request account For Sync Licensing</h4>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <input
            type="text"
            id="firstName"
            v-model="firstName"
            required
            placeholder="First Name"
            @blur="validateField('firstName')"
            :class="{ 'error-border': error.firstName }"
          >
          <p v-if="error.firstName" class="error-message">{{ error.firstName }}</p>
        </div>

        <div class="form-group">
          <input
            type="text"
            id="lastName"
            v-model="lastName"
            required
            placeholder="Last Name"
            @blur="validateField('lastName')"
            :class="{ 'error-border': error.lastName }"
          >
          <p v-if="error.lastName" class="error-message">{{ error.lastName }}</p>
        </div>

        <div class="form-group">
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="Email"
            @blur="validateField('email')"
            :class="{ 'error-border': error.email }"
          >
          <p v-if="error.email" class="error-message">{{ error.email }}</p>
        </div>

        <div class="form-group">
          <textarea
            id="note"
            v-model="note"
            rows="4"
            required
            placeholder="How did you hear about us?"
            @blur="validateField('note')"
            :class="{ 'error-border': error.note }"
          ></textarea>
          <p v-if="error.note" class="error-message">{{ error.note }}</p>
        </div>

          <!-- <ReCaptchaV2 
          sitekey="6Letp74pAAAAAO8aqkgX--N3qXYf7yMsQwXCgCIr"
          @verify="onVerify"
          @expired="onExpired"
        /> -->
        <vue-recaptcha :sitekey="CAPTCHA_SITE_KEY"
          size="small" 
          theme="light"
          hl="en"
          @verify="onVerify"
          @expire="onExpired"
          ref="vueRecaptcha">
        </vue-recaptcha>

        <button :disabled="isFormInvalid() || !captchaVerified"
          :style="{ backgroundColor: isFormInvalid() || !captchaVerified ? '#b2d8b5' : '#86bc25' }">
          Request
        </button>

      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import { VueRecaptcha } from 'vue-recaptcha';
import vueRecaptcha from 'vue3-recaptcha2';
import { useRouter } from "vue-router";

export default {
  name: "RequestView",
  components: {
    vueRecaptcha 
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      note: '',
      CAPTCHA_SITE_KEY: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      error: {
        firstName: '',
        lastName: '',
        email: '',
        note: ''
      },
      captchaVerified: false
    };
  },
  methods: {
    onVerify(response) {
      this.captchaVerified = true;
    },
    onExpired() {
      console.log('CAPTCHA expired');
      this.captchaVerified = false;
      this.$refs.vueRecaptcha.reset();
    },
    handleSubmit() {
      if (this.isFormInvalid()) {
        alert('Please correct the errors before submitting.');
        return;
      }
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        note: this.note
      };
      axios.post(axios.defaults.baseURL + 'account/lisync/sendEmail', data)
        .then(response => {
          alert('Request submitted successfully.');
          this.resetForm();
        })
        .catch(error => {
          console.error('Error submitting form:', error);
        });
    },
    validateField(field) {
      if (field === 'email') {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.error.email = re.test(this.email) ? '' : 'Please enter a valid email address.';
      } else {
        this.error[field] = this[field].trim().length === 0 ? 'This field is required.' : '';
      }
    },
    isFormInvalid() {
      return !this.firstName.trim() || !this.lastName.trim() || !this.note.trim() || !!this.error.email;
    },
    resetForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.note = '';
      this.error = { firstName: '', lastName: '', email: '', note: '' };
      this.captchaVerified = false;
      this.$refs.vueRecaptcha.reset();
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/global-styles/colors.scss";
@import "@/global-styles/typography.scss";
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}
.login {
  width: 400px;
}

.request {
  position: absolute;
  top: 40px;
  right: 40px;
  color: rgba(255, 255, 255, 0.3);

  a {
    color: white;
  }
}

.faded_h1 {
  @include heading-1(rgba(255, 255, 255, 0.5));
}

.green_h1 {
  @include heading-1($green);
}

h4 {
  margin: 0;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}

input {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}
textarea {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

button {
  background: #86bc25;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  margin-top: 20px;

  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  margin-bottom: 40px;
}

a {
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}

.error-border {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 14px;
}

</style>

