<template>
  <div class="container">
    <div class="request">
      Don't have a liSynce account?
      <router-link to="/request">Request an account</router-link>
    </div>
    <div class="login">
      <h1>
        <span class="faded_h1">li</span>Sync<span class="faded_h1">e</span
        ><span class="green_h1">.</span>
      </h1>
      <h4>Sign in For Sync Licensing</h4>
      <input type="email" name="email" v-model="email" placeholder="Email" />
      <input
        type="password"
        name="password"
        v-model="password"
        placeholder="Password"
      />
      <button @click.prevent="submit">Sign In</button>
      <router-link to="/recover">Forgot your password?</router-link>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  name: "SignIn",
  setup() {
    const email = ref("");
    const password = ref("");
    const router = useRouter();
    const submit = async () => {
      const response = await axios.post("account/lisynce/login", {
        username: email.value,
        pword: password.value,
      });
      if (response.data.status == 'success') {
        localStorage.setItem("token", response.data.key);
        localStorage.setItem("organization_ids", JSON.stringify(response.data.organization_ids));
        localStorage.setItem("userId", response.data.user_id);
        localStorage.setItem("name", response.data.name);
        console.log(response);
        await router.push({ path: "/" });
      } else {
        alert(response.data.message)
      }
    };
    return {
      email,
      password,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/global-styles/colors.scss";
@import "@/global-styles/typography.scss";
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}
.login {
  width: 400px;
}

.request {
  position: absolute;
  top: 40px;
  right: 40px;
  color: rgba(255, 255, 255, 0.3);

  a {
    color: white;
  }
}

.faded_h1 {
  @include heading-1(rgba(255, 255, 255, 0.5));
}

.green_h1 {
  @include heading-1($green);
}

h4 {
  margin: 0;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}

input {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

button {
  background: #86bc25;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  margin-top: 20px;

  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  margin-bottom: 40px;
}

a {
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}
</style>
