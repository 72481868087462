<template>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-5xl">
      <h2 class="text-xl font-bold text-gray-900 mb-4">Step 1: Event Information</h2>
      
        <div class="space-y-4">
          <!-- <div :class="[
                    !form.eventDate || !form.eventName ? 'border-red-100' : 'border-green-100', 
                    'p-4', 
                    'bg-white', 
                    'shadow-md', 
                    'rounded-md', 
                    'border-2', 
                    'relative'
                  ]">
            <div class="absolute top-0 left-0 right-0 px-3 py-1 mt-6t" :class="[
                    !form.eventDate || !form.eventName ? 'bg-red-100' : 'bg-green-100', 
                  ]">
              <h4 class=" font-bold text-sm text-left" :class="[
                    !form.eventDate || !form.eventName ? 'text-red-700' : 'text-green-700', 
                  ]">Event Details</h4>
            </div>
            
            <p v-if="!form.eventId" class="text-gray-500 mb-4 text-xs text-left mt-6">
              Enter the event information below and it will see if the event already exists in our system. If the event is not found, you will be prompted to create the event. Once the first video from your new event has been successfully uploaded, the event will be available for easy selection for any future uploads.
            </p>
            <div v-if="!form.eventId">
              <div class="flex mb-4 space-x-4">
                <div class="w-1/2">
                  <input v-model="form.eventDate" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event_date" type="date" placeholder="Event Date">
                </div>

                <div class="w-1/2 flex">
                  <input v-model="searchEventQuery" class="shadow appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event_name" type="text" placeholder="Enter event name, city or state">
                  <div class="bg-gray-200 flex items-center justify-center w-12 rounded-r">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" stroke-width="2.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                      </svg>
                  </div>
                </div>
                <ul v-if="searchEventQuery" class="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg w-48">
                    <li v-for="event in filteredEvents" :key="event.id" @click="addEvent(event)" class="cursor-pointer p-3 hover:bg-gray-100">
                      {{ event.name }}
                    </li>
                  </ul>
              </div>
              // remove this button <button @click='addEvent()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                Add Event
              </button> 
            </div>
            <div v-else class="mt-8">
              <p class="text-gray-900 mb-1 text-xs text-left">
                Event:
              </p>
              <hr class="mt-2">
              <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                {{ form.eventName }}
              </p>
              <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                {{ form.eventDate }}
              </p>
              <div class="text-left">
                <button @click='clearEvent()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                  Clear Event
                </button>
              </div>
            </div>
          </div>
          <div :class="[
                    !form.organization ? 'border-red-100' : 'border-green-100', 
                    'p-4', 
                    'bg-white', 
                    'shadow-md', 
                    'rounded-md', 
                    'border-2', 
                    'relative'
                  ]">
              <div class="absolute top-0 left-0 right-0 px-3 py-1 mt-6t" :class="[
                    !form.organization ? 'bg-red-100' : 'bg-green-100', 
                  ]">
                <h4 class=" font-bold text-sm text-left" :class="[
                      !form.organization ? 'text-red-700' : 'text-green-700', 
                    ]">Hosting Organization</h4>
              </div>
              <div v-if="!form.organization">
                <p class=" mb-4 text-lg text-center mt-8" :class="[
                      !form.organization ? 'text-red-700' : 'text-green-700', 
                    ]">
                  Please select the event above.
                </p>
              </div>
              <div v-else class="mt-8">
                <p class="text-gray-900 mb-1 text-xs text-left">
                  Hosting Organization:
                </p>
                <hr class="mt-2">
                <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                  {{ selectedOrganisation.name }}
                </p>
                <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                  {{ selectedOrganisation.address1 }}
                </p>
                <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                  {{ selectedOrganisation.address }}
                </p>
                <div class="text-left"> 
                  <button @click='clearOrganization()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                    Clear Organisation
                  </button>
                </div>
              </div>
            </div> -->
            <div :class="[
                    form.school == '' || form.ensemble == '' ? 'border-red-100' : 'border-green-100', 
                    'p-4', 
                    'bg-white', 
                    'shadow-md', 
                    'rounded-md', 
                    'border-2', 
                    'relative'
                  ]">
              <div class="absolute top-0 left-0 right-0 px-3 py-1 mt-6t" :class="[
                      !form.school || !form.ensemble ? 'bg-red-100' : 'bg-green-100', 
                    ]">
                <h4 class=" font-bold text-sm text-left" :class="[
                      !form.school || !form.ensemble ? 'text-red-700' : 'text-green-700', 
                    ]">Ensemble Information</h4>
              </div>
              <div v-if="!showSchool">
                <p class="text-gray-500 mb-4 text-xs text-left mt-6">
                  Enter the name of the performing ensemble's school or organization in the search box below and click on search button. if the organization is not listed, confirm spelling or try another keyword to find. If the organization is list, use the '+Select' button. if the organization does not populate, you will be prompted to create a new school/organization. After selecting the performing organization, selecting the appropriate ensemble from the populated list or create a new one if necessary.
                </p>
                <p class="text-gray-500 mb-4 text-lg text-left">
                  Search First For The <span class="underline">Performing Ensemble's</span> School/Organization
                </p>
                <p class="text-gray-500 mb-4 text-xs text-left">
                  Search for a school/ganization below. Organizations wil populate as you type. Select an existing school/organization or create a new school/organization if necessary.
                </p>
                <div class="mb-4" v-if="form.school == ''">
                  <div class="flex">
                    <input v-model="searchSchoolQuery" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event_date" type="text" placeholder="Enter School/Organization">
                    <button @click="searchOrganisation()" class="bg-gray-200 flex items-center justify-center w-12 rounded-r">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" stroke-width="2.5" stroke="currentColor" class="w-6 h-6 text-gray-600">
                          <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                      </svg>
                    </button>
                  </div>
                  <ul v-if="searchSchoolQuery || userId != 1" class="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg w-48">
                    <li v-for="school in filteredSchool" :key="school.id" @click="addSchool(school)" class="cursor-pointer p-3 hover:bg-gray-100">
                      {{ school.name }}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <div>
                    <p class="text-gray-900 mb-1 text-xs text-left">
                      School/Organization Name:
                    </p>
                    <hr class="mt-2">
                    <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                      {{ selectedSchool.name }}
                    </p>
                    <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                      {{ selectedSchool.address }}
                    </p>
                    <div class="text-left"> <!-- This ensures the button inside aligns to the left -->
                      <button @click='clearSchool()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-4 mt-6" v-if="form.school != ''">
                  <input v-model="searchEnsembleQuery" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event_date" type="text" placeholder="Enter Ensemble">
                  <ul  v-if="searchEnsembleQuery|| userId != 1" class="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg w-48">
                    <li v-for="ensemble in filteredEnsemble" :key="ensemble.id" @click="addEnsemble(ensemble)" class="cursor-pointer p-3 hover:bg-gray-100">
                      {{ ensemble.name }}
                    </li>
                  </ul>
                </div>
              </div>

              <div v-else class="mt-8 flex">
                <div class="flex-1">
                  <p class="text-gray-900 mb-1 text-xs text-left">
                    School/Organization Name:
                  </p>
                  <hr class="mt-2">
                  <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                    {{ selectedSchool.name }}
                  </p>
                  <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                    {{ selectedSchool.address1 }}
                  </p>
                  <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                    {{ selectedSchool.address }}
                  </p>
                  <div class="text-left"> <!-- This ensures the button inside aligns to the left -->
                    <button @click='clearSchool()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                      Clear
                    </button>
                  </div>
                </div>
                <div class="flex-1">
                  <p class="text-gray-900 mb-1 text-xs text-left">
                    Ensemble Information:
                  </p>
                  <hr class="mt-2">
                  <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                    {{ selectedEnsemble.name }}
                  </p>
                  <div class="text-left"> <!-- This ensures the button inside aligns to the left -->
                    <button @click='clearEnsemble()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                      Clear 
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4 bg-red-100 shadow-md rounded-md border-2 border-red-100 relative mt-6" v-if="errors.length>0">
              <p v-for="error in errors" class="text-red-700 mb-1 text-xs text-left">
                    {{'* ' + error}}
                  </p>
            </div>
        </div>
      
        <div class="mt-8 flex justify-between">
            <button class="bg-gray-200 text-gray-800 rounded-lg px-5 py-2 hover:bg-gray-300" @click="emitClose()">Cancel</button>
            <button @click="next()" class="bg-red-500 text-white rounded-lg px-5 py-2 hover:bg-red-600">Next</button>
        </div>
    </div>
  </template>

  <script setup>
  import { defineEmits, onMounted, ref, computed, watch } from 'vue';
  import axios from 'axios';

  const emit = defineEmits(['next', 'close']);

  const form = ref({
    // eventDate: '',
    // eventId: '',
    // eventName: '',
    // organization: '',
    // eventName: 'BOA - Louisville Regional Championship - 2014',
    school: '',
    ensemble: '0',
  });
  const errors = ref([]);
  const userId = ref(localStorage.getItem('userId'));

  // const organizations = ref([
  //   { id: 1, name: 'Organization One', address: '123 First Street' },
  //   { id: 2, name: 'Organization Two', address: '456 Second Avenue' },
  //   { id: 3, name: 'Organization Three', address: '789 Third Boulevard' }
  // ]);
  const schools = ref([]);
  const ensembles = ref([]);


  // const showEvent = ref(false);
  // const showOrganisation = ref(false);
  const showSchool = ref(false);
  // const searchEventQuery = ref('');
  // const searchOrganizationQuery = ref ('');
  const searchSchoolQuery = ref ('');
  const searchEnsembleQuery = ref ('');
  // const selectedOrganisation = ref({
  //   id: '',
  //   name: '',
  //   address1: '',
  //   address: ''
  // });
  const selectedSchool = ref({
    id: '',
    name: '',
    address1: '',
    address: '',
  });
  const selectedEnsemble = ref({
    id: '',
    name: '',
  });
  let searchTimeout = null;
//   const requirementTypes = ref([]);
//   const showError = ref(false);

//   onMounted(async () => {
//     try {
//       const response = await axios.get('/requirement-types');
//       requirementTypes.value = response.data;
//     } catch (error) {
//       console.error('Error fetching requirement types:', error);
//     }
//   });
onMounted(() => {
  if (userId.value != 1) {
    searchOrganisation();
  }
});
async function fetchEnsemblesForOrganization(organizationId) {
  try {
    const response = await axios.post(axios.defaults.baseURL + "organization/ensemble/search/", {
      organization_id: organizationId
    });
    ensembles.value = response.data.results; // Assuming the response data is the array of ensembles
  } catch (error) {
    console.error('Error fetching ensembles:', error);
    ensembles.value = []; // Reset on error
  }
};

  // const clearEvent = () => {
  //   form.value.eventDate = '';
  //   form.value.eventName = '';
  //   form.value.eventId = '';
  //   form.value.organization = '';
  //   selectedOrganisation.value = '';
  //   showEvent.value = false;
  //   searchEventQuery.value = '';
  //   };
  // const addEvent = (event) => {
  //   // if (form.value.eventDate == '' || form.value.eventName == '' ) {
  //   //   const iserrorExists = errors.value.some((error) => error === 'Please complete all the event fields');
  //   //   if (!iserrorExists){
  //   //     errors.value.push('Please complete all the event fields');
  //   //   }
  //   //   return
  //   // } else {
  //     errors.value = errors.value.filter(error => error !== 'Please complete all the event fields');
  //     form.value.eventId = event.id;
  //     form.value.eventName = event.name;
  //     form.value.eventDate = event.startdate;
  //     form.value.organization = event.organization.id?event.organization.id:'';
  //     selectedOrganisation.value.id = event.organization.id?event.organization.id:'';
  //     selectedOrganisation.value.name = event.organization.name;
  //     selectedOrganisation.value.address1 = event.organization.address.address1;
  //     selectedOrganisation.value.address = `${event.organization.address.city}, ${event.organization.address.state} ${event.organization.address.zip}`;
  //     searchEventQuery.value = '';
  //     console.log(event.organization.id);
  //     showEvent.value = true;
  //   // }
  // };

  watch(searchSchoolQuery, async (newValue, oldValue) => {
  if (newValue !== oldValue && newValue !='') {
    if (searchTimeout) clearTimeout(searchTimeout); // Clear existing timeout
    searchTimeout = setTimeout(() => {
      searchOrganisation(); // Call your function after 1 second of inactivity
    }, 1000);
  }
});

  async function searchOrganisation() {
    // if(searchSchoolQuery.value !=''){
      const orgsnisations = await axios.post(axios.defaults.baseURL + "organization/name-search/", {
      name: searchSchoolQuery.value,
      userId: userId.value,
      organizations: JSON.parse(localStorage.getItem("organization_ids"))
    });
    schools.value = orgsnisations.data.results;
    console.log(orgsnisations.data);
    // } else{
    //   return
    // }
  };

//   onMounted(async () => {
//   try {
//     const response = await axios.post(axios.defaults.baseURL + "video/event-search/search");
//     eventResults.value = response.data.results; // Store the response data in eventResults

//     // const orgsnisations = await axios.post(axios.defaults.baseURL + "organization/search/", {
//     //   perPage: 100
//     // });
//     // schools.value = orgsnisations.data.results; // Store the response data in schools
//   } catch (error) {
//     console.error('Error fetching events:', error);
//   }
  
//   // Your existing onMounted code for fetching requirement types
// });
  // const filteredEvents = computed(() => {
  //     return searchEventQuery.value ? eventResults.value.filter(e => e.name.toLowerCase().includes(searchEventQuery.value.toLowerCase())) : [];
  // });
  // const filteredOrganization = computed(() => {
  //   return searchOrganizationQuery.value ? organizations.value.filter(e => e.name.toLowerCase().includes(searchOrganizationQuery.value.toLowerCase())) : [];
  // });
  const filteredSchool = computed(() => {
    return searchSchoolQuery.value ? schools.value.filter(e => e.name.toLowerCase().includes(searchSchoolQuery.value.toLowerCase())) : (userId.value != 1)? schools.value : [];
  });
  const filteredEnsemble = computed(() => {
    return searchEnsembleQuery.value ? ensembles.value.filter(e => e.name.toLowerCase().includes(searchEnsembleQuery.value.toLowerCase())) : ensembles.value;
  });

  // const addOrganization = (organization) => {
  //   errors.value = errors.value.filter(error => error !== 'Select host organization');
  //   form.value.organization =  organization.id;
  //   selectedOrganisation.value = organization;
  //   showOrganisation.value = true;
  //   searchOrganizationQuery.value = '';
  // };
  // const clearOrganization = () => {
  //   form.value.eventDate = '';
  //   form.value.eventName = '';
  //   form.value.eventId = '';
  //   selectedOrganisation.value = '';
  //   form.value.organization =  '';
  //   searchEventQuery.value = '';
  //   showOrganisation.value = false;
  // };
  async function addSchool(school) {
    errors.value = errors.value.filter(error => error !== 'Select school/organization');
    form.value.school =  school.id;
    selectedSchool.value.id = school.id;
    selectedSchool.value.name = school.name;
    if(school.address.address1 != null && school.address.city != null && school.address.state != null && school.address.zip != null) {
      selectedSchool.value.address1 = school.address.address1;
      selectedSchool.value.address = `${school.address.city}, ${school.address.state} ${school.address.zip}`;
    }
    if (form.value.school) {
    await fetchEnsemblesForOrganization(form.value.school);
    }
    selectedEnsemble.value = '';
    form.value.ensemble =  '';
    searchSchoolQuery.value = '';
  };
  const clearSchool = () => {
    selectedSchool.value.id = '';
    selectedSchool.value.name = '';
    selectedSchool.value.address1 = '';
    selectedSchool.value.address = '';
    form.value.school =  '';
    selectedEnsemble.value= '';
    form.value.ensemble =  '';
    showSchool.value = false;
  };
  const addEnsemble = (ensemble) => {
    errors.value = errors.value.filter(error => error !== 'Select ensemble');
    showSchool.value = true;
    form.value.ensemble =  ensemble.id;
    selectedEnsemble.value = ensemble;
    searchEnsembleQuery.value = '';
  };
  const clearEnsemble = () => {
    selectedEnsemble.value.id = '';
    selectedEnsemble.value.name = '';
    form.value.ensemble =  '';
    showSchool.value = false;
  };
    

  const next = () => {
    if(validate()){
      return
    }
    emit('next', {
            step: 1,
            data: form.value
        });
  };

  const validate = () => {
    let flag = false;
    // if (form.value.eventDate == '' || form.value.eventName ==''){
    //   const iserrorExists = errors.value.some((error) => error === 'Please complete all the event fields');
    //   if (!iserrorExists){
    //     errors.value.push('Please complete all the event fields');
    //   }
    //   flag = true;
    // }
    // if (form.value.organization == ''){
    //   const iserrorExists = errors.value.some((error) => error === 'Select host organization');
    //   if (!iserrorExists){
    //     errors.value.push('Select host organization');
    //   }
    //   flag = true;
    // }
    if (form.value.school == ''){
      const iserrorExists = errors.value.some((error) => error === 'Select school/organization');
      if (!iserrorExists){
        errors.value.push('Select school/organization');
      }
      flag = true;
    }
    if (form.value.ensemble == ''){
      const iserrorExists = errors.value.some((error) => error === 'Select ensemble');
      if (!iserrorExists){
        errors.value.push('Select ensemble');
      }
      flag = true;
    }
    return flag;
  };
  const emitClose = () => {
    emit('close');
  };
  </script>

  <style>
  /* Additional custom styling if needed */
  </style>
