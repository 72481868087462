<template>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-5xl">
        <h2 class="text-xl font-bold text-gray-900 mb-4">Add New Composition</h2>
        <div class="space-y-4">
            <div class="p-4 bg-white shadow-md rounded-md border-2 border-green-100 relative">
                <div class="absolute top-0 left-0 right-0 bg-green-100 px-3 py-1 mt-6t">
                <h4 class="text-green-700 font-bold text-sm">Add</h4>
                </div>
                <div class="grid grid-cols-2 gap-4 mt-12">
                    <label for="title" class="text-gray-700 text-sm font-bold flex items-center">Title</label>
                    <input v-model="form.title" id="title" class="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Title"/>
                    
                    <label for="artist" class="text-gray-700 text-sm font-bold flex items-center">Original Artist (If Known):</label>
                    <input v-model="form.artist_names" id="artist" class="text-xs shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Name"/>
                    
                    <label for="composer" class="text-gray-700 text-sm font-bold flex items-center">Original Composer (If Known):</label>
                    <input v-model="form.composer_names" id="composer" class="text-xs shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Name"/>
                    
                    <label for="composeremail" class="text-gray-700 text-sm font-bold flex items-center">Composer Email Address (If Known):</label>
                    <input v-model="form.composerEmail" id="composeremail" class="text-xs shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter Email"/>
                    
                </div>
                <div class="p-4 bg-red-100 shadow-md rounded-md border-2 border-red-100 relative mt-6" v-if="form.title == ''">
                    <p class="text-red-700 mb-1 text-xs text-left">
                        * Title is required
                    </p>
                </div>
            </div>
        </div>
      
        <div class="mt-8 flex justify-between">
            <button class="bg-gray-200 text-gray-800 rounded-lg px-5 py-2 hover:bg-gray-300" @click="emitClose()">Cancel</button>
            <div class="flex items-center space-x-3">
                <button class="bg-red-800 text-white rounded-lg px-5 py-2 hover:bg-red-900" @click="emitSave()">Save</button>
            </div>
        </div>
    </div>
  </template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, reactive, computed, watch } from 'vue';

const emit = defineEmits(['close', 'save']);

// Define props
const props = defineProps({
  titleQuery: String,
  artistQuery: String,
  composerQuery: String
});

const form = ref({
  id: 0,
  title: '',
  artist_names: '',
  composer_names: '',
  composerEmail: ''
});

// Initialize form values with the props
onMounted(() => {
  form.value.title = props.titleQuery || '';
  form.value.artist_names = props.artistQuery || '';
  form.value.composer_names = props.composerQuery || '';
});
const emitClose = () => {
  emit('close');
};

const emitSave = () => {
  if(form.value.title == '') {
    return
  }
  console.log(form.value)
    emit('save', form.value);
}


</script>

<style>
/* Additional custom styling if needed */
</style>
