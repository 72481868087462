<template>
  <div class="container flex flex-col items-center pt-12 pb-12 px-4 md:px-0">
    <div class="w-full md:w-2/3 lg:w-2/2 mx-auto bg-gray-800 p-8 rounded-lg shadow-md">
      <h1 class="text-white text-2xl mb-4">Agreement</h1>

      <!-- PDF.js Viewer -->
      <div id="pdf-viewer" ref="pdfViewer" class="w-full h-96 mb-4 bg-white"></div>

      <!-- Checkbox Agreement Section -->
      <div class="mb-4">
        <label v-if="showError" class="text-red-500 text-left">Please check both boxes to proceed.</label>
        <div class="flex items-center mb-2">
          <input type="checkbox" v-model="checkboxesRead" :class="{'border-red-500': !checkboxesRead && showError}" class="mr-2" />
          <label class="text-white">I have read and understood the terms and conditions of the License Agreement</label>
        </div>
        <div class="flex items-center mb-4">
          <input type="checkbox" v-model="checkboxesConsent" :class="{'border-red-500': !checkboxesConsent && showError}" class="mr-2" />
          <label class="text-white">I consent to the ESIGN Act disclosure</label>
        </div>
      </div>

      <!-- Signature Pad -->
      <div class="mb-4">
        <canvas ref="signaturePad" class="w-full h-40 bg-gray-100 rounded shadow-md"></canvas>
        <button @click="clearSignature" class="bg-red-500 text-white py-1 px-4 mt-2 rounded-md">Clear Signature</button>
      </div>

      <!-- Sign and Submit Button -->
      <button @click="signDocument" class="bg-green-600 text-white py-3 px-6 rounded-md w-full">Sign Document</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { jsPDF } from "jspdf";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { useStore } from 'vuex';
// GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.min.mjs";

export default {
  name: "FuneralAgreement",
  data() {
    return {
      checkboxesRead: false,
      checkboxesConsent: false,
      signaturePad: null,
      document: '',
      showError: false,
      funeralForm : {}
    };
  },
  computed: {
    canSign() {
      return this.checkboxesRead && this.checkboxesConsent;
    },
  },
  mounted() {
    // GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
    this.document = this.$store.getters.getDocument; // Retrieve the document from Vuex
    this.funeralForm = this.$store.getters.getFuneralForm;
    console.log('Document prop:', this.document);
    if (this.document) {
      this.loadPDF();
      this.initSignaturePad();
    } else {
      console.error('Document prop is undefined.');
    }
  },
  methods: {
    loadPDF() {
      const pdfViewer = this.$refs.pdfViewer;
      // Clear the previous canvas if it exists
      pdfViewer.innerHTML = ''; 

      // Create a new canvas element
      const pdfUrl = this.document; // Use the URL stored in Vuex

      getDocument(pdfUrl).promise.then(pdf => {
        // Loop through all the pages of the PDF
        for (let i = 1; i <= pdf.numPages; i++) {
          pdf.getPage(i).then(page => {
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = document.createElement('canvas');
            pdfViewer.appendChild(canvas);
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            
            const context = canvas.getContext('2d');
            page.render({ canvasContext: context, viewport: viewport });
          });
        }
      });
    },
    initSignaturePad() {
      const canvas = this.$refs.signaturePad;
      const ctx = canvas.getContext("2d");
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let drawing = false;

      canvas.addEventListener("mousedown", () => {
        drawing = true;
        ctx.beginPath();
      });

      canvas.addEventListener("mousemove", (event) => {
        if (!drawing) return;
        ctx.lineWidth = 2;
        ctx.lineCap = "round";
        ctx.strokeStyle = "#000000";
        ctx.lineTo(event.offsetX, event.offsetY);
        ctx.stroke();
      });

      canvas.addEventListener("mouseup", () => {
        drawing = false;
      });

      this.signaturePad = ctx;
    },
    clearSignature() {
      const canvas = this.$refs.signaturePad;
      this.signaturePad.clearRect(0, 0, canvas.width, canvas.height);
    },
    signDocument() {
      if (!this.canSign) {
        this.showError = true;
        return;
      }
      if (this.canSign) {
        this.showError = false;
        const canvas = this.$refs.signaturePad;
        const imgData = canvas.toDataURL("image/png");

        axios.post(axios.defaults.baseURL + 'account/lisynce/signFuneralFormAgreement', {funeralForm: this.funeralForm, signatureImg: imgData}, {responseType: 'blob'})
        .then(response => {
          //download the pdf agreement
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'signed_funeral_agreement.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(error => {
          console.error('Error retrieving agreement:', error);
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  margin: 0 auto;
}
#pdf-viewer {
  overflow-y: auto;
  max-width: 100%;
  height: 100vh; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

canvas {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  max-width: 100%;
  height: auto;
}
</style>
  