<template>
  <div class="pagination">
    <button
      @click="goToPage(1)"
      :disabled="currentPage === 1"
      class="pagination-button first"
    >
      «
    </button>
    <button
      @click="goToPage(currentPage - 1)"
      :disabled="currentPage === 1"
      class="pagination-button"
    >
      ‹
    </button>
    <button
      v-for="page in pages"
      :key="page"
      @click="goToPage(page)"
      :class="['pagination-button', { 'current-page': currentPage === page }]"
    >
      {{ page }}
    </button>
    <button
      @click="goToPage(currentPage + 1)"
      :disabled="currentPage === totalPages"
      class="pagination-button"
    >
      ›
    </button>
    <button
      @click="goToPage(totalPages)"
      :disabled="currentPage === totalPages"
      class="pagination-button last"
    >
      »
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  },
  computed: {
    pages() {
      const start = Math.max(this.currentPage - 3, 1);
      const end = Math.min(this.currentPage + 2, this.totalPages);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit("pageChanged", page);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* Adds space between buttons */
}

.pagination-button {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
  margin: 0;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 0.375rem; /* 6px */
}

.pagination-button:hover:not(.current-page):not(:disabled) {
  background-color: #eff6ff;
  border-color: #60a5fa;
}

.pagination-button:disabled {
  cursor: default;
  opacity: 0.5;
}

.current-page {
  background-color: #2563eb; /* Blue 600 */
  color: white;
  border-color: #2563eb; /* Blue 600 */
}

.first:disabled,
.last:disabled {
  display: none; /* Hides the first and last button when disabled */
}
</style>
