import axios from "axios";

const VideoService = {
  async channelVideos() {
    return axios.post(axios.defaults.baseURL + "video/lisynce/");
  },
  async videos() {
    return axios.post(axios.defaults.baseURL + "video/lisynce/videos", {
      userId: localStorage.getItem('userId'),
      organizations: JSON.parse(localStorage.getItem('organization_ids'))
    });
  },
};

export default VideoService;
